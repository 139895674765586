<span class="an-heart-icon" tabindex="0" role="button" arial-label="save vehicle" [id]="id" [ngClass]="{'on': state}" (click)="clickHeart()" (keyup.enter)="clickHeart()">
  <svg class="an-svg" viewBox="0 0 30 30" role="img" [attr.aria-label]="(state ? 'Saved ' : 'Save ') + ariaLabel" [ngStyle]="{ width: width, height: height }">
    <use class="an-svg-use an-svg-use-fill" xlink:href="#heart" />
    <use class="an-svg-use an-svg-use-outline" xlink:href="#heart" />
  </svg>

  <svg class="an-svg an-hide" viewBox="0 0 30 30">
    <defs>
      <path id="heart"
        d="M15,4.875A8.224,8.224,0,0,0,7.5,0,7.265,7.265,0,0,0,0,7.312C0,13.047,6.322,17.482,15,26c8.678-8.518,15-12.953,15-18.688A7.265,7.265,0,0,0,22.5,0,8.22,8.22,0,0,0,15,4.875Z" />
    </defs>
  </svg>

</span>