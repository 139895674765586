import { AfterViewInit, Component, EventEmitter, HostListener, Inject, OnInit, Output } from '@angular/core';
import { IAddVehicle, IVehicleDetails, IUpdateOwnedVehicleDetailsRequest, IVehicleColorsRequest, Datum } from './add-vehicle.interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AnAddVehicleService } from './add-vehicle.service';

@Component({
  selector: 'add-vehicle',
  templateUrl: './add-vehicle.component.html',
  styleUrls: ['./add-vehicle.component.scss']
})
export class AddVehicleComponent implements OnInit, AfterViewInit {
  form: FormGroup;
  vehicles!: IVehicleDetails[];
  myCar: any;
  isDefaultNotNeeded: boolean = true;
  validAddVehicleCount = 0;
  validVehicleTrim : any = [];
  validVehicleModel : any = [];
  isDoneActive = false;
  tabs: any;
  rightside: any;
  scrollLeft = 0;
  scrollRight = 0;
  leftside: any;
  dragging = false;
  showSpinner = false;
  maxscroll = 0;
  data!: IAddVehicle;
  isLocked: boolean = true;
  seatColor: any = [];
  exteriorColor: any = [];
  trimDD: any =[];
  isRemoveDiasbleSpreadOut: boolean = false;
  showAddVehicleModal: boolean = false;
  finishVehicle?: IVehicleDetails;
  isFinishAddingFlow: boolean = false;
  isRemoveDiasble: boolean = false;
  updateVehReq: any = {};
  isenableFinishDone: boolean = false;
  isFinishVehicleModelNeeded: boolean = false;
  isAddVehicleModelNeeded: boolean = false;
  finsihVehicleModel: string = '';
  ismobile = false;
  trimValue: any = {};
  isSpreadOut = false;
  @Output() onModalClose: EventEmitter<any> = new EventEmitter<any>();

  get anSelectModelControl() {
    return this.form.get('anSelectModel')
  }

  protected readonly _unsubscribe$ = new Subject<void>();
  constructor(private fb: FormBuilder, private anAddVehicleService: AnAddVehicleService) {
    this.form = this.fb.group({  anaddVehModel:[], anInputNickName: [], anInputMileage: [],anSelect: [], anSelectTrim: [], anSelectModel:[], anSelectSeatMaterial: [], anSelectExteriorColor: [], anSelectSeatColor: [], });
  }



  ngOnInit(): void {
    this.data = this.anAddVehicleService?.vehicleData;
    this.vehicles = this.anAddVehicleService?.vehicleData?.vehciles || [];
    this.isSpreadOut = this.vehicles.length === 1;
    this.maxscroll = this.vehicles.length > 3 ? this.vehicles.length - 3 : 0;
    if (window.innerWidth < 992) { // 768px portrait
      this.ismobile = true;
      this.isSpreadOut = false;
  }
    this.vehicles.forEach((veh: IVehicleDetails) => {
      let anSelectItems: any = [];
      anSelectItems.push({ value: `${veh.VehicleId}|select`, description: 'Select' });
      veh.Trim.forEach((t:Datum) => {
        anSelectItems.push({ value: `${veh.VehicleId}|${t.id}`, description: t.name });

      })
      this.trimValue[veh.VehicleId] = anSelectItems;
    });

    this.form.get("anaddVehModel")?.valueChanges.pipe(takeUntil(this._unsubscribe$))
      .subscribe((val) => (this.updateTrim(val)));
    this.form.get("anSelect")?.valueChanges.pipe(takeUntil(this._unsubscribe$))
      .subscribe((val) => (this.enableAddVehicle(val)));
      this.form.get("anSelectTrim")?.valueChanges.pipe(takeUntil(this._unsubscribe$))
      .subscribe((val) => (this.enableFinsihVehicle(val)));
      this.form.get("anSelectModel")?.valueChanges.pipe(takeUntil(this._unsubscribe$))
      .subscribe((val) =>  (this.updateDropdown(val, 'model')));
      this.form.get("anInputMileage")?.valueChanges.pipe(takeUntil(this._unsubscribe$))
      .subscribe((val) => (this.updateVehReq.mileage = val));
      this.form.get("anSelectSeatMaterial")?.valueChanges.pipe(takeUntil(this._unsubscribe$))
      .subscribe((val) => (this.updateDropdown(val,'seatMaterial')));
      this.form.get("anSelectExteriorColor")?.valueChanges.pipe(takeUntil(this._unsubscribe$))
      .subscribe((val) =>  (this.updateDropdown(val,'exteriorColor')));
      this.form.get("anSelectSeatColor")?.valueChanges.pipe(takeUntil(this._unsubscribe$))
      .subscribe((val) => (this.updateDropdown(val,'interiorColor')));
      this.form.get("anInputNickName")?.valueChanges.pipe(takeUntil(this._unsubscribe$))
      .subscribe((val) => (this.updateVehReq.nickname =val));

    this.isFinishAddingFlow = this.data?.isFinishAddingFlow;
    this.finishVehicle = this.data?.finishVehicle;
    this.isFinishVehicleModelNeeded = this.finishVehicle?.Trim.length === 0;
    if(this.isFinishAddingFlow) {
      this.finsihVehicleModel = this.data.finishVehicle?.Model || '';
      this.trimDD.push({value: `${this.finishVehicle?.VehicleId}|select`, description: 'Select' });
      this.seatColor.push({value: `${this.finishVehicle?.VehicleId}|select`, description: 'Select' });
      this.data.finishVehicle?.interiorColors.forEach((i: string) => {
        this.seatColor.push({value: `${this.finishVehicle?.VehicleId}|${i}`, description: i });
      });
      this.exteriorColor.push({value: `${this.finishVehicle?.VehicleId}|select`, description: 'Select' });
      this.data.finishVehicle?.exteriorColors.forEach((i: string) => {
        this.exteriorColor.push({value: `${this.finishVehicle?.VehicleId}|${i}`, description: i });
      });
    }

    if(this.isFinishAddingFlow && this.finishVehicle?.selectedSeatMaterial !== '') {
      const val =  this.finishVehicle?.selectedSeatMaterial;
      this.form.get("anSelectSeatMaterial")?.patchValue( { value: `${this.finishVehicle?.VehicleId}|${val}`, description: val });
    }
    if(this.isFinishAddingFlow && this.finishVehicle?.selectedExteriorColor !== '') {
      const val =  this.finishVehicle?.selectedExteriorColor;
      this.form.get("anSelectExteriorColor")?.patchValue( { value: `${this.finishVehicle?.VehicleId}|${val}`, description: val });
    }
    if(this.isFinishAddingFlow && this.finishVehicle?.selectedSeatColor !== '') {
      const val =  this.finishVehicle?.selectedSeatColor;
      this.form.get("anSelectSeatColor")?.patchValue( { value: `${this.finishVehicle?.VehicleId}|${val}`, description: val });
    }
    if(this.isFinishAddingFlow && this.finishVehicle?.givenNickName !== '') {
      const val =  this.finishVehicle?.givenNickName;
      this.form.get("anInputNickName")?.patchValue(val);
    }
    if(this.isFinishAddingFlow && this.finishVehicle?.estimatedMileage) {
      const val =  this.finishVehicle?.estimatedMileage;
      this.form.get("anInputMileage")?.patchValue(val);
    }
    if (this.vehicles.length <= 3) {
      document.getElementById('rightArr-chev')?.classList.add('disable');
      document.getElementById('leftArr-chev')?.classList.add('disable');
    }
  }

  ngAfterViewInit(): void {
    this.tabs = document.querySelector('.chev-wrapper');
    this.rightside = document.querySelector('.chev-wrapper .right-arrow');
    this.leftside = document.querySelector('.chev-wrapper .left-arrow');
    if (!this.isFinishAddingFlow) this.manageArrows();

  }

  isModelNeeded(vehicle: IVehicleDetails){
    return vehicle.Trim.length === 0;
  }

  getVehicleModel(vehicle: IVehicleDetails) {
    if(vehicle){
      let anSelectItems: any = [];
      anSelectItems.push({ value: `${vehicle.VehicleId}|select`, description: 'Select' });
      vehicle.models.forEach((item: any) => {
        anSelectItems.push({ value: `${vehicle.VehicleId}|${item.id}`, description: item.name });
      })
      return anSelectItems;
    }
  }
  updateDropdown(val: any, key: any) {
    const vehId = val ? val.value.split('|')[0] : '';
    const modelKey = val ? val.value.split('|')[1] || '' : '';
    if(val && val.description && val.description.toLowerCase() !== 'select') {
      this.updateVehReq[key] = val.description;
      if(key === 'model') {

        this.anAddVehicleService.getTrims(modelKey).subscribe({
          next: (res: any) => {
            let anSelectItems: any = [];
            anSelectItems.push({ value: `${vehId}|select`, description: 'Select' });
            if (res.Success && res.Data) {
              res.Data.forEach((val: Datum) => {
                anSelectItems.push({ value: `${vehId}|${val.id}`, description: val.name })
              });
              this.trimDD = anSelectItems;
            }
            else {
              this.trimDD = anSelectItems;
            }
         }
        });
        const reqObject : IVehicleColorsRequest = {
          year : this.finishVehicle?.Year || '',
          make: this.finishVehicle?.Make || '',
          model: val.description,
          trim: 'trim'
        }
         this.anAddVehicleService.getVehicleColors(reqObject).subscribe({
        next: (res: any) => {
          if (res.Success && res.Data && res.Data.exteriorColors && res.Data.interiorColors) {
            this.exteriorColor = [];
            this.exteriorColor.push({ value: `${vehId}|select`, description: 'Select' });

            res.Data.exteriorColors.forEach((val: any, index: Number) => {
              this.exteriorColor.push({ value: `${vehId}|${val.ColorName}`, description: val.ColorName });
            });

            this.seatColor = [];
            this.seatColor.push({ value: `${vehId}|select`, description: 'Select' });

             res.Data.interiorColors.forEach((val: any) => {
              this.seatColor.push({ value: `${vehId}|${val}`, description: val });
            });

            this.isenableFinishDone = false;
            this.form.get("anSelectExteriorColor")?.patchValue({ value: `${vehId}|select`, description: 'Select' });
            this.form.get("anSelectSeatColor")?.patchValue({ value: `${vehId}|select`, description: 'Select' });
          }
        },
        error: () => {
          this.isenableFinishDone = false;
        }
      });
      }
    } else {
      delete this.updateVehReq[key];
      this.finsihVehicleModel = '';
      if(key === 'model') {
        let anSelectItems: any = [];
        anSelectItems.push({ value: `${vehId}|select`, description: 'Select' });
        this.trimDD = anSelectItems;
        this.isenableFinishDone = false;
      }
    }
  }

  updateTrim(val: any) {
    const vehId = val.value.split('|')[0];
    const modelId = val.value.split('|')[1];
    if(val && val.description && val.description.toLowerCase() !== 'select') {
        this.anAddVehicleService.getTrims(modelId).subscribe({
          next: (res: any) => {
            if (res.Success && res.Data) {
              let anSelectItems = [];
              anSelectItems.push({ value: `${vehId}|select`, description: 'Select' });
              this.validVehicleModel.push({'vehicleId' : vehId, 'model':val.description});
               res.Data.forEach((da: Datum) => {
                anSelectItems.push({value: `${vehId}|${da.id}`, description: da.name })
              });
              this.trimValue[vehId] =anSelectItems;
            }
            else {
              this.trimValue[vehId] = [{ value: `${vehId}|select`, description: 'Select' }];
              if(this.validVehicleModel.length > 0) {
                const index = this.validVehicleModel.findIndex((v: any) => v.vehicleId === vehId);
                this.validVehicleModel.splice(index, 1);
              }
            }
         }
        })
    }  else {
      if(this.validVehicleModel.length > 0) {
        const index = this.validVehicleModel.findIndex((v: any) => v.vehicleId === vehId);
        this.validVehicleModel.splice(index, 1);
        this.validAddVehicleCount--;
        let anSelectItems = [];
        anSelectItems.push({ value: `${vehId}|select`, description: 'Select' });
        this.trimValue[vehId] =anSelectItems;
      }
      const enable = document.getElementById(`an-vehicle-${vehId}`);
      enable?.classList.remove("enableAddVehicle");
    }
  }
  isImageURLValid = (image: any) => {
    if(!image.ImageUrl){

      return false;
    } else {
      return true;
    }
  }

  getAddnlDetailsForFinsihVehicle = (detail: any) => {
    let anSelectItems: any = [];
    anSelectItems.push({ value: `${this.finishVehicle?.VehicleId}`, description: 'Select' });
    const data: any = this.finishVehicle;
    data[detail].forEach((item: any) => {

      anSelectItems.push({ value: `${this.finishVehicle?.VehicleId}|${item.id}`, description: item.name });
    });
    return anSelectItems;
  }

  getSeatMaterial = () => {
    let anSelectItems: any = [];

    anSelectItems.push({ value: `${this.finishVehicle?.VehicleId}`, description: 'Select' });
    this.finishVehicle?.SeatMaterial.forEach((item: any) => {
      anSelectItems.push({ value: `${this.finishVehicle?.VehicleId}|${item.value}`, description: item.description });
    })

    return anSelectItems;
  }
  getId = (vehicle: IVehicleDetails,) => {
    return 'an-select-' + vehicle.VehicleId;
  }
  enableAddVehicle = (item: any) => {
    const vehicleId = item.value.split('|')[0] || '';
    const trim = item.description || '';
    const enable = document.getElementById(`an-vehicle-${vehicleId}`);
    if (item.description.toLowerCase() === 'select') {
      this.validAddVehicleCount--;
      enable?.classList.remove("enableAddVehicle");
      this.isDoneActive = this.validAddVehicleCount > 0;
      if(this.validVehicleTrim.length > 0) {
        const index = this.validVehicleTrim.findIndex((v: any) => v.vehicleId === vehicleId);
        this.validVehicleTrim.splice(index, 1);
      }

    } else {
      enable?.classList.add("enableAddVehicle");
      this.validAddVehicleCount++;
      this.validVehicleTrim.push({'vehicleId' : vehicleId, trim});
    }
  }

  enableFinsihVehicle = (item: any) => {
    if (item.description.toLowerCase() === 'select') {
      this.isenableFinishDone = false;

    } else {

      const model= this.updateVehReq['model'];

      this.updateVehReq.trim = item.description;
      this.isenableFinishDone = true;


    }
  }

  removeVehicle = (pos: Number, vehicleDetails: IVehicleDetails, isOnlyVehicle?: boolean) => {
    let vehicleAdded: any = null;
    let enable : any = null;
    if(!isOnlyVehicle) {
      const vehicleClassName = `an-vehicle-remove-${vehicleDetails.VehicleId}`;
      vehicleAdded = document.getElementById(vehicleClassName);
      vehicleAdded?.classList.add('disableRemove');
      enable = document.getElementById(`an-vehicle-${vehicleDetails.VehicleId}`);
      enable?.classList.remove("enableAddVehicle");
    }

    this.anAddVehicleService.rejectOwnVehicleDetails(vehicleDetails.VehicleId).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.onModalClose.emit({action: 'remove', vehicle:vehicleDetails});
          this.isDoneActive = true;
          this.vehicles = this.vehicles.filter(item=> item.VehicleId !== vehicleDetails.VehicleId);
          this.showAddVehicleModal = this.vehicles.length !== 0;
          this.manageArrows();
          this.isSpreadOut = this.vehicles.length === 1;
        }
      },
      error: () => {
        if(!isOnlyVehicle) {
        vehicleAdded.classList.remove('disableRemove');
        console.log(this.validVehicleTrim, vehicleDetails.VehicleId);
        this.isDoneActive = this.validAddVehicleCount > 0;

        const trimSelected = this.validVehicleTrim.filter((x: any)=> x.vehicleId === vehicleDetails.VehicleId);
        if(trimSelected.length > 0)
          enable?.classList.add("enableAddVehicle");
        }
      }
    });
  }
  addVehicle = (pos: Number, vehicleDetails: IVehicleDetails, isOnlyVehicle?: boolean) => {
    const trim = this.validVehicleTrim.filter((x: any) => x.vehicleId === vehicleDetails.VehicleId);
    const className = `an-vehicle-image-${pos}`;
    let spinnerImage: any = document.getElementById(className);
    const vehicleClassName = `an-vehicle-added-${pos}`;
    let vehicleAdded: any = document.getElementById(vehicleClassName);
    const vehicle: any = `an-vehicle-div-${pos}`;
    let vehicleData: any = document.getElementById(vehicle);
    spinnerImage.classList.add('spinner-image');
    const anselected: any = `an-select-${vehicleDetails.VehicleId}`;
    let anSelect: any = document.getElementById(anselected);
    this.showAddVehicleModal = true;
    this.onModalClose.emit({action: 'add', vehicle:vehicleDetails});
    let update: IUpdateOwnedVehicleDetailsRequest = {};

    const model = this.validVehicleModel.filter((x: any) => x.vehicleId === vehicleDetails.VehicleId);
    if(model.length > 0) {
      update = { ...update, trim: trim[0].trim, vehicleStatus: 'CONFIRMED', model: model[0].model };

    } else {
      update = { ...update, trim: trim[0].trim, vehicleStatus: 'CONFIRMED' };
    }
    const vehicleRemoveName = `an-vehicle-remove-${vehicleDetails.VehicleId}`;
    let remove: any = document.getElementById(vehicleRemoveName);
    remove.classList.add('disableRemove');
    this.anAddVehicleService.updateOwnVehicleDetails(vehicleDetails.VehicleId, update).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.isDoneActive = this.validAddVehicleCount > 0;
          spinnerImage.classList.remove('spinner-image');
          vehicleAdded.classList.add('an-vehicle-added');
          vehicleData.classList.add('dnone');
          anSelect.disabled = true;
          remove.classList.add('disableRemove');

          // if(this.isSpreadOut && this.vehicles.length === 1) {
            if((this.isSpreadOut || this.ismobile) && this.vehicles.length === 1) { // bug fix #451969
            this.onModalClose.emit({action: 'done', vehicle:vehicleDetails});
          }
        }
      },
      error: () => {
        spinnerImage.classList.remove('spinner-image');
        remove.classList.remove('disableRemove');
      }
    });
  }
  scrollTabRight() {
    this.tabs.scrollLeft += 265;

    setTimeout(() => {
      this.scrollRight += 1;
      this.scrollLeft += 1;
      this.manageArrows();
    }, 100)

  }

  scrollTabLeft() {
    this.tabs.scrollLeft -= 265;
    setTimeout(() => {
      this.scrollRight -= 1;
      this.scrollLeft -= 1;
      this.manageArrows();
    }, 100)

  }

  manageArrows() {

    if (this.scrollLeft > 0) {
      this.leftside?.classList.remove('disable');
    } else {
      this.leftside?.classList.add('disable');
    }
    if (this.scrollRight === this.maxscroll) {
      this.rightside?.classList.add('disable');
    } else {
      this.rightside?.classList.remove('disable');
    }
    if (this.vehicles.length <= 3) {
      document.getElementById('rightArr-chev')?.classList.add('disable');
      document.getElementById('leftArr-chev')?.classList.add('disable');
    }
  }

  closeModal(doAuth?: string) {
    const update: IUpdateOwnedVehicleDetailsRequest = this.updateVehReq;

    const veh : string = this.finishVehicle?.VehicleId || '';
    if(this.isFinishAddingFlow && doAuth === 'done')  {
      this.anAddVehicleService.updateOwnVehicleDetails(veh, update).subscribe({
        next: (res: any) => {
          if (res.Success) {
            const vehData = this.isFinishAddingFlow ? {
              VehicleId: this.finishVehicle?.VehicleId,
              Year: this.finishVehicle?.Year,
              Make: this.finishVehicle?.Make,
              Model: this.finishVehicle?.Trim.length === 0 ? this.updateVehReq?.model || '' : this.finishVehicle?.Model,
              Trim: this.updateVehReq?.trim,
              ExteriorColor: this.updateVehReq?.exteriorColor || '',
              SeatMaterial: this.updateVehReq?.seatMaterial || '',
              SeatColor: this.updateVehReq?.seatColor || '',
              Mileage: this.updateVehReq?.mileage || '',
              NickName: this.updateVehReq?.nickName || '',
            } : null;
            this.showAddVehicleModal = false;
            this.onModalClose.emit({action: this.isFinishAddingFlow ? 'finishdone' : doAuth , vehicle:this.isFinishAddingFlow ? vehData : null});
          }
        },
        error: () => {
          console.log('error');
        }
      });

    }
  else {
    this.showAddVehicleModal = false;
    this.onModalClose.emit({action: doAuth, vehicle:null});
  }
  }
  /* istanbul ignore next */
  @HostListener('window:keydown', ['$event'])
  onKeyDownHandler(event: KeyboardEvent) {
    if (event.key === 'Tab' && this.showAddVehicleModal === true) {
      event.preventDefault();
      //this.controlTabNavigation(event);
    }
    if (event.key === 'Escape' || event.keyCode === 27) {
      this.closeModal('askmelater');
      event.preventDefault();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.ismobile = event.target.innerWidth <= 992;
    this.isSpreadOut = false;
  }

  // @HostListener('mousedown', ['$event']) onMouseDown(event: any) {
  //   if (this.tabs.contains(event.target)) {
  //     this.dragging = true;
  //   }


  // }

  // @HostListener('mousemove', ['$event']) onMouseMove(event: any) {
  //   if (this.tabs.contains(event.target)) {
  //     this.drag(event);
  //     setTimeout(() => {
  //       this.manageChevrons();
  //     }, 500)
  //   }
  // }
  // @HostListener('touchmove', ['$event']) onTouchMove(event: any) {
  //   if (this.tabs.contains(event.target)) {
  //     this.drag(event);
  //     setTimeout(() => {
  //       this.manageChevrons();
  //     }, 500)
  //   }
  // }

  // @HostListener('document:keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
  //   if (event.key == 'ArrowRight' || event.key == 'ArrowLeft') {
  //     setTimeout(() => {
  //       this.manageChevrons();
  //     }, 500)
  //   }
  // }

  @HostListener('mouseup', ['$event']) onMouseUp(event: any) {
    // this.dragging = false;
    // this.tabs.classList.remove('dragging');
    // event.preventDefault();
    const container : any= document.getElementById('container');
    if (!container.contains(event.target)) {
      this.closeModal('askmelater');
    }
  }

  // drag(e: any) {
  //   if (!this.dragging) return;
  //   this.tabs.classList.add('dragging');
  //   console.log(this.tabs.scrollLeft, e.movementX)
  //   this.tabs.scrollLeft -= e.movementX;
  //   if(this.tabs.scrollLeft === 0) {
  //     this.scrollLeft = 0;
  //   } else {
  //     this.scrollLeft +=1;
  //   }
  //   if(this.tabs.scrollLeft >=790) {
  //     this.rightside.classList.add('disable');
  //   }
  // }

}
