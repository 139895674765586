<an-base-modal (onCloseModal)="close()">
  <div class="an-store-hours">
    <div class="an-store-hours__title">
      Sales Center
    </div>
    <div class="an-store-hours__content">
      <div class="an-store-hours__detail" [ngClass]="{'an-store-hours--selected': index === currentDay }"
        *ngFor="let detailedHour of data?.department?.DetailedHours; let index = index;">
        <span> {{ detailedHour.Day | weekDay }} </span>
        <span> {{ detailedHour.StartTime }} - {{ detailedHour.EndTime }} </span>
      </div>
    </div>
  </div>
</an-base-modal>