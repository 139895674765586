import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'an-base-modal',
  templateUrl: './base-modal.component.html',
  styleUrls: ['./base-modal.component.scss']
})
export class BaseModalComponent implements OnInit {
  @Output() onCloseModal = new EventEmitter();
  @Input() backgroundColor!: string;

  color!: string;

  constructor() { }

  ngOnInit(): void {
    this.color = this.backgroundColor || 'white';
  }

}
