<div class="an-input-email form-group">
  <label [for]="'id-' + label" class="an-input-email__label"> {{ label }} </label>
  <a href="javascript:void(0)" class="an-input-email__label-tooltip" *ngIf="emailControl.disabled" (click)="clickHelp()"></a>
  <input type="text" maxlength="25" class="an-input-email__control form-control" [id]="'id-' + label"
    [placeholder]="placeholder" [formControl]="emailControl">
  <a href="javascript:void(0)" class="an-input-email__clear" *ngIf="hasError && emailControl.enable" (click)="clear()"></a>
  <a href="javascript:void(0)" class="an-input-email__tooltip" *ngIf="emailControl.disabled" (click)="clickHelp()"></a>
  <div class="an-input-email__error-message" *ngIf="hasError">
    {{ emailControl.hasError('required') ? requiredErrorMessage : invalidErrorMessage }}
  </div>
</div>