import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'an-input-email',
  templateUrl: './input-email.component.html',
  styleUrls: ['./input-email.component.scss']
})
export class InputEmailComponent {

  @Input()
  label!: string;

  @Input()
  placeholder!: string;

  @Input()
  requiredErrorMessage!: string;

  @Input()
  invalidErrorMessage!: string;

  @Input()
  type!: string;

  @Input()
  emailControl!: FormControl;

  @Output()
  onClickHelp = new EventEmitter();

  get hasError() {
    return this.emailControl.errors && !this.emailControl.pristine;
  }

  clear() {
    this.emailControl.setValue('');
    this.emailControl.markAsPristine()
  }

  clickHelp() {
    this.onClickHelp.emit();
  }

}
