import { DOCUMENT } from '@angular/common';
import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Inject, Injectable, Injector, Renderer2, RendererFactory2 } from '@angular/core';
import { IConfirmModalLabels, MODAL_DATA } from './constants';
import { Observable, Subject } from 'rxjs';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { AppointmentModalV2Component } from './appointment-modal-v2/appointment-modal-v2.component';

interface Config {
  maxWidth: string;
  escKeyClose?: boolean;
  backdropClickClose?: boolean;
  backdropColor?: string;
  mobileFullSize?: boolean;
  hideScrollbar?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AnModalService {
  private renderer!: Renderer2;
  private mediaQueryList!: MediaQueryList;
  private divModalContainer!: HTMLElement;
  private divModalDialog!: HTMLElement;
  private config!: Config;

  private modals: { ref: ComponentRef<unknown>, el: HTMLElement, onClosed: Subject<boolean>, config: Config }[] = [];
  private modalsCount = 0;

  constructor(
    private injector: Injector,
    private applicationRef: ApplicationRef,
    @Inject(DOCUMENT) private document: Document,
    private componentFactoryResolver: ComponentFactoryResolver,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.mediaQueryList = window.matchMedia("(max-width: 767px)");
    this.createModalContainer();
  }

  open(component: any, data: any, config: Config): { ref: ComponentRef<unknown>, el: HTMLElement, onClosed: Subject<boolean>, close: () => void } {
    this.setDefaultConfigValues(config);
    if(config.hideScrollbar) {
      this.hideScrollbar();
    }

    const componentSelector = this.document.createElement('an-modal-component');
    this.renderer.addClass(componentSelector, 'an-modal-component');
    this.renderer.setStyle(componentSelector, 'width', '100%');

    const injector = Injector.create([{ provide: MODAL_DATA, useValue: data }], this.injector);
    const factory = this.componentFactoryResolver.resolveComponentFactory(component);
    const componentRef = factory.create(injector, [], componentSelector);
    this.applicationRef.attachView(componentRef.hostView);

    let modal = this.createModal(componentSelector, config.maxWidth);

    const modalResult = {
      ref: componentRef,
      el: modal,
      onClosed: new Subject<boolean>(),
      close: () => this.close()
    };

    this.modals.push({ ...modalResult, config });

    return modalResult;
  }

  openConfirm(labels: IConfirmModalLabels, config?: Config): ConfirmModalComponent {
    labels.modalType = labels.modalType || 'confirm';
    if (!config) {
      config = {
        maxWidth: '504px'
      }
    }
    const modal = this.open(ConfirmModalComponent, labels, config).ref.instance as ConfirmModalComponent;
    modal.onClose.subscribe(_ => this.close());

    return modal;
  }

  openAppointmentModalV2(src: string, config: Config): Observable<{ confirm: boolean }> {
    const data = {
      src,
      id: 'appointment-iframe-id',
      title: 'Appointment',
      maxWidth: config.maxWidth,
      onData: new Subject<any>()
    };

    this.open(AppointmentModalV2Component, data, {
      ...config,
      backdropClickClose: false,
      escKeyClose: false,
    });

    return data.onData;
  }

  close() {
    const modal = this.modals.pop();
    if (modal) {
      this.modalsCount--;
      modal.onClosed.next(true);
      this.renderer.removeChild(this.divModalContainer, modal.el);
      this.showScrollbar();
    }
  }

  private setDefaultConfigValues(config: Config) {
    if (config.backdropClickClose === undefined) config.backdropClickClose = true;
    if (config.escKeyClose === undefined) config.escKeyClose = true;
    if (config.backdropColor === undefined) config.backdropColor = 'rgba(0, 0, 0, 0.6)';
    if (config.backdropColor === 'transparent') config.backdropColor = 'rgba(0, 0, 0, 0.4)';
    if (config.mobileFullSize === undefined) config.mobileFullSize = true;
    if (config.hideScrollbar === undefined) config.hideScrollbar = true;

    this.config = config;
  }

  private createModalContainer() {
    this.divModalContainer = this.renderer.createElement('div');
    this.renderer.addClass(this.divModalContainer, 'an-modal-container');
    this.renderer.appendChild(this.document.body, this.divModalContainer);

    this.closeOnKeyEsc();
  }

  private createModal(componentSelector: HTMLElement, maxWidth: string): HTMLElement {
    const divModal = this.renderer.createElement('div');
    this.renderer.addClass(divModal, 'an-modal');
    this.renderer.setAttribute(divModal, 'id', 'an-modal-' + this.modalsCount++);

    this.renderer.setStyle(divModal, 'display', 'block');
    this.renderer.setStyle(divModal, 'position', 'fixed');
    this.renderer.setStyle(divModal, 'top', 0);
    this.renderer.setStyle(divModal, 'left', 0);
    this.renderer.setStyle(divModal, 'z-index', 10600);
    this.renderer.setStyle(divModal, 'width', '100%');
    this.renderer.setStyle(divModal, 'height', '100%');
    this.renderer.setStyle(divModal, 'overflow', 'hidden');
    this.renderer.setStyle(divModal, 'outline', 0);
    this.renderer.setStyle(divModal, 'background-color', this.config.backdropColor);

    this.handleBackdropClickClose(divModal);

    this.divModalDialog = this.renderer.createElement('div');
    this.renderer.addClass(this.divModalDialog, 'an-modal-dialog');
    this.renderer.setStyle(this.divModalDialog, 'display', 'flex');
    this.renderer.setStyle(this.divModalDialog, 'transform', 'none');

    this.handleMobileFullSize();

    this.renderer.appendChild(this.divModalDialog, componentSelector);
    this.renderer.appendChild(divModal, this.divModalDialog);
    this.renderer.appendChild(this.divModalContainer, divModal);

    return divModal;
  }

  private handleBackdropClickClose(modal: HTMLElement) {
    if (this.config.backdropClickClose) {
      this.renderer.listen(modal, 'click', (e) => {
        if (e.target.className === 'an-modal' || e.target.className === 'an-modal-dialog') {
          this.close();
        }
      });
    }
  }

  private handleMobileFullSize() {
    if (this.config.mobileFullSize) {
      if (this.mediaQueryList.matches) {
        this.renderer.setStyle(this.divModalDialog, 'height', '100%');
      } else {
        this.removeFullSize(this.config.maxWidth);
      }
      this.mediaQueryList.addEventListener('change', this.sizeListener);
    } else {
      this.removeFullSize(this.config.maxWidth);
    }
  }

  private sizeListener = (e: MediaQueryListEvent) => {
    if (e.matches) {
      this.setFullSize();
    } else {
      this.removeFullSize(this.config.maxWidth);
    }
  };

  private setFullSize() {
    this.renderer.setStyle(this.divModalDialog, 'height', '100%');
    this.renderer.removeStyle(this.divModalDialog, 'align-items');
    this.renderer.removeStyle(this.divModalDialog, 'margin');
    this.renderer.removeStyle(this.divModalDialog, 'max-width');
  }

  private removeFullSize(maxWidth: string) {
    this.renderer.setStyle(this.divModalDialog, 'height', 'calc(100% - 3.5rem)');
    this.renderer.setStyle(this.divModalDialog, 'margin', '1.75rem auto');
    this.renderer.setStyle(this.divModalDialog, 'align-items', 'center');
    this.renderer.setStyle(this.divModalDialog, 'max-width', maxWidth);
  }

  private closeOnKeyEsc(): void {
    this.renderer.listen(this.document, 'keydown', (event: KeyboardEvent) => {
      if (event.key == 'Escape' && this.modalsCount > 0) {
        const modal = this.modals[this.modalsCount - 1];
        if (modal.config.escKeyClose) {
          this.close();
        }
      }
    });
  }

  private hideScrollbar(): void {
    if (this.hasVerticalScroll()) {
      this.renderer.setStyle(this.document.documentElement, 'overflow', 'hidden');
      if (!this.mediaQueryList.matches) {
        this.renderer.setStyle(this.document.documentElement, 'padding-right', '17px');
      }
    }
  }

  private showScrollbar(): void {
    if (this.hasVerticalScroll() && this.modals.length === 0) {
      this.renderer.removeStyle(this.document.documentElement, 'overflow');
      this.renderer.removeStyle(this.document.documentElement, 'padding-right');
    }
  }

  private hasVerticalScroll(): boolean {
    if (window.innerHeight) {
      return this.document.body.offsetHeight > window.innerHeight;
    }
    else {
      return this.document.documentElement.scrollHeight >
        this.document.documentElement.offsetHeight ||
        this.document.body.scrollHeight > this.document.body.offsetHeight;
    }
  }
}
