import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'an-heart-icon',
  templateUrl: './an-heart-icon.component.html',
  styleUrls: ['./an-heart-icon.component.scss']
})
export class AnHeartIconComponent implements OnInit {
  @Output() onClickHeartIcon = new EventEmitter<boolean>();

  @Input() state = false;
  @Input() id!: string;
  @Input() ariaLabel!: string;

  @Input() iconWidth!: string;
  get width() {
    return this.iconWidth || '30px';
  }

  @Input() iconHeight!: string;
  get height() {
    return this.iconHeight || '30px';
  }

  constructor() { }

  ngOnInit(): void {
  }

  clickHeart() {
    this.onClickHeartIcon.emit(this.state);
  }

}
