import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'an-appt-time',
  templateUrl: './appt-time.component.html',
  styleUrls: ['./appt-time.component.scss']
})
export class ApptTimeComponent implements OnInit, OnChanges {

  @Input()
  timeTitle!: string;

  @Input()
  hours!: string[];

  @Input()
  timeControl!: FormControl;

  constructor() { }

  ngOnInit(): void {
    this.timeControl.setValue(this.hours[0]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['hours']) {
      this.timeControl.setValue(changes['hours'].currentValue[0]);
    }
  }

}
